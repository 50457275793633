import { Fn }			from "ts-base/fn";
import { Endo }			from "ts-base/endo";
import { Zoomer }		from "ts-base/zoomer";
import { Maybe }		from "ts-base/maybe";

import { FormDom }		from "@v3/geko/form/form";

import { Logger }			from "@geotoura/shared/logger";
import * as i18n			from "@geotoura/shared/i18n";
import * as commonModel		from "@geotoura/shared/commonModel";
import * as floaterModel	from "@geotoura/shared/floaterModel";

import * as connect		from "@geotoura/common/util/connect";
import * as Server		from "@geotoura/common/Server";
import * as tracking	from "@geotoura/common/privacy/tracking";

import {
	Model,
	OpenState,
	FormState,
	InquiryModel,
	InquirySchema,
	MeetingGeneralModel,
	MeetingGeneralSchema,
	MeetingPhoneModel,
	MeetingPhoneSchema,
}	from "@geotoura/floater/model";

const logger	= Logger.create("floater-actions");

//-----------------------------------------------------------------------------
//## refresh

let handle:connect.Upd<Model>|null	= null;

export const setRefresh	= (it:connect.Upd<Model>):void => { handle = it; };

const refresh:connect.Upd<Model>	= (change:Endo<Model>):void => {
	if (handle === null)	throw new Error("refresh was called before setRefresh");
	handle(change);
};

//===============================================================================
// Zoomer
//===============================================================================

const modelZoomer	=
	Zoomer.on<Model>();

const openZoomer:Zoomer<Model, OpenState|null>	=
	modelZoomer.atKey("open");

const inquiryDataZoomer:Zoomer<Model, InquiryModel>	=
	modelZoomer.atKey("inquiry").atKey("data");

const inquiryStateZoomer:Zoomer<Model, FormState>	=
	modelZoomer.atKey("inquiry").atKey("state");

const meetingGeneralZoomer:Zoomer<Model, MeetingGeneralModel>	=
	modelZoomer.atKey("meeting").atKey("data").atKey("general");

const meetingPhoneZoomer:Zoomer<Model, MeetingPhoneModel>	=
	modelZoomer.atKey("meeting").atKey("data").atKey("phone");

const meetingStateZoomer:Zoomer<Model, FormState>	=
	modelZoomer.atKey("meeting").atKey("state");

//===============================================================================
// Actions
//===============================================================================

export const toggle	= (target:"inquiry"|"planner"|"meeting", display:"attached"|"centered"):void => {
	tracking.gaSendEvent({
		action:		`${target}-toggledialog`,
		category:	target,
		label:		display === "attached" ? `${target}-floaterbutton` : `${target}-pagebutton`,
		value:		1,
	});

	refresh(
		openZoomer.mod((open) => open === null || open.content !== target ? ({
			content:	target,
			display:	display,
		}) : null)
	);
};

export const close	= ():void =>
	refresh(
		openZoomer.set(null)
	);

// TODO replace with own api call with only needed data
export const getData	= async (languageCode:i18n.LanguageCode, exampleRouteId:commonModel.ExampleRouteId):Promise<void> => {
	try {
		const json	= await Server.getRouteName({ lang: languageCode, id: exampleRouteId });

		refresh(
			modelZoomer.atKey("routeName").set(json.name)
		);
	}
	catch (e) {
		logger.error("Could not fetch routeName, ", e);
	}
};

//-------------------------------------------------------------------------------
// Inquire Form
//-------------------------------------------------------------------------------

export const modifyInquiryForm:Fn<Endo<InquiryModel>, void> =
	Fn.andThen(inquiryDataZoomer.mod, refresh);

export const sendInquiry = (languageCode: i18n.LanguageCode):void =>
	refresh((model) =>
		Maybe.cata(buildInquiryRequestData(languageCode, model))(
			() => {
				// no valid form data available

				window.setTimeout(() => FormDom.exposeFirstError(document), 100);

				return inquiryDataZoomer.mod(InquirySchema.touch);
			},
			(requestData) => {
				// form is filled out correctly

				void actuallySendRequest(requestData);

				return inquiryStateZoomer.set("enroute");
			}
		)(model)
);

const buildInquiryRequestData	= (languageCode:i18n.LanguageCode, model:Model):Maybe<floaterModel.InquiryData>	=>
	Maybe.map(InquirySchema.extract(model.inquiry.data))((formData):floaterModel.InquiryData => ({
		lang:			languageCode,
		email:			formData.email,
		date:			formData.date,
		notes:			formData.notes,
		privacy:		"",
		territoryId:	model.pageInfo.type === "territory"
							? model.pageInfo.territoryId
							: null,
		regionId:		model.pageInfo.type === "region"
							? model.pageInfo.regionId
							: null,
		routeId:		model.pageInfo.type === "region" || model.pageInfo.type === "route"
							? model.pageInfo.routeId
							: null,
		url:			window.location.href,
	}));

const actuallySendRequest	= async (requestData:floaterModel.InquiryData):Promise<void>	=> {
	try {
		const json:floaterModel.RequestResponse	= await Server.setInquiry(requestData);

		tracking.gaSendEvent({
			action:		"inquiry-submit",
			category:	"inquiry",
			label:		requestData.url,
			value:		10,
		});

		refresh(inquiryStateZoomer.set(json.return));
	}
	catch (e) {
		logger.error("caught", e);
		refresh(inquiryStateZoomer.set("error"));
	}
};

//-------------------------------------------------------------------------------
// Meeting Form
//-------------------------------------------------------------------------------

export const modifyMeetingGeneralForm:Fn<Endo<MeetingGeneralModel>, void> =
	// Fn.andThen(meetingGeneralZoomer.mod, refresh);
	(change) => {
		/*
		our form lib does not support conditional fields yet:
		whenever a conditional field becomes visible, it has to be "un-touched"
		we do this manually for the phone field here which is hidden depending on which contactWay is selected
		*/
		refresh((old) => {
			// this we would return for the standard general form change
			const next	= meetingGeneralZoomer.mod(change)(old);

			// NOTE these are safe because contactWay is a FreeSelect, so this cannot ever be None
			const oldContactWay	= Maybe.unsafeGet(MeetingGeneralSchema.fields.contactWay.extract(old.meeting.data.general.contactWay));
			const nextContactWay	= Maybe.unsafeGet(MeetingGeneralSchema.fields.contactWay.extract(next.meeting.data.general.contactWay));

			// if the contactWay changed, we additionally un-touch the phone form
			if (oldContactWay !== nextContactWay) {
				return meetingPhoneZoomer.mod(MeetingPhoneSchema.untouch)(next);
			}
			else {
				return next;
			}
		});
	};

export const modifyMeetingPhoneForm:Fn<Endo<MeetingPhoneModel>, void> =
	Fn.andThen(meetingPhoneZoomer.mod, refresh);

export const sendMeeting = (languageCode: i18n.LanguageCode):void =>
	refresh((model) =>
		Maybe.cata(buildMeetingRequestData(languageCode, model))(
			() => {
				// no valid form data available

				window.setTimeout(() => FormDom.exposeFirstError(document), 100);

				return Fn.andThen(
					meetingGeneralZoomer.mod(MeetingGeneralSchema.touch),
					meetingPhoneZoomer.mod(MeetingPhoneSchema.touch)
				);
			},
			(requestData) => {
				// form is filled out correctly

				void actuallySendMeetingRequest(requestData);

				return meetingStateZoomer.set("enroute");
			}
		)(model)
	);

const buildMeetingRequestData	= (languageCode:i18n.LanguageCode, model:Model):Maybe<floaterModel.MeetingData>	=>
	Maybe.map(Model.extractMeetingData(model))((data):floaterModel.MeetingData => ({
		lang:			languageCode,
		email:			data.general.email,
		phone:			data.phone.phone,
		notes:			data.general.notes,
		contactWay:		data.general.contactWay,
		territoryId:	model.pageInfo.type === "territory" ? model.pageInfo.territoryId : null,
		regionId:		model.pageInfo.type === "region" ? model.pageInfo.regionId : null,
		routeId:		model.pageInfo.type === "region" || model.pageInfo.type === "route" ?
						model.pageInfo.routeId : null,
		privacy:		"",
	}));

const actuallySendMeetingRequest	= async (requestData:floaterModel.MeetingData):Promise<void> => {
	try {
		const json:floaterModel.RequestResponse	= await Server.setMeeting(requestData);

		tracking.gaSendEvent({
			action:		"meeting-submit",
			category:	"meeting",
			label:		requestData.contactWay,
			value:		10,
		});

		refresh(meetingStateZoomer.set(json.return));
	}
	catch (e) {
		logger.error("caught", e);
		refresh(meetingStateZoomer.set("error"));
	}
};
