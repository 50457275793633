import { Nullable }		from "ts-base/nullable";

import * as commonModel	from "@geotoura/shared/commonModel";

export type PageInfo = FrontPageInfo | RoutePageInfo | RegionPageInfo | TerritoryPageInfo;

export type FrontPageInfo = {
	type: "front",
};

export type RoutePageInfo = {
	type:		"route",
	routeId:	commonModel.ExampleRouteId,
};

export type RegionPageInfo = {
	type:		"region",
	routeId:	commonModel.ExampleRouteId,
	regionId:	commonModel.RegionId,
};

export type TerritoryPageInfo = {
	type:			"territory",
	territoryId:	commonModel.TerritoryId,
};

export const get = ():PageInfo => {
	const routeId		= Nullable.then(document.body.getAttribute("data-route"))(commonModel.ExampleRouteId.fromString);
	const regionId		= Nullable.then(document.body.getAttribute("data-region"))(commonModel.RegionId.fromString);
	const territoryId	= Nullable.then(document.body.getAttribute("data-territory"))(commonModel.TerritoryId.fromString);

	return (
		routeId !== null && regionId === null ?
			{
				type: "route",
				routeId,
			}
			:
		routeId !== null && regionId !== null ?
			{
				type: "region",
				routeId,
				regionId,
			}
			:
		territoryId !== null ?
			{
				type: "territory",
				territoryId,
			}
			:
		// else
			{
				type: "front",
			}
	);
};
